.benefits {
  padding: 0 0 26rem;
  overflow-x: hidden;

  @include md {
    padding: 0 0 80px;
  }

  @include xs {
    padding: 0 0 60px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 6rem;

    @include md {
      margin: 0 0 45px;
    }

    .title {
      max-width: 70rem;
      margin: 0 2rem 0 0;

      @include md {
        max-width: 500px;
      }

      @include xs {
        max-width: 220px;
      }
    }
  }

  &__nav {
    display: flex;
  }

  &__nav-btn {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 11rem;
    height: 11rem;
    border: 0.1rem solid $colorWhite;
    border-radius: 50%;
    padding: 1rem;
    background: transparent;
    cursor: pointer;
    transition: border .3s;

    @include md {
      width: 48px;
      height: 48px;
    }

    @include xs {
      width: 36px;
      height: 36px;
    }

    &:hover {
      border-color: #d9c08d;

      svg {
        fill: #d9c08d;
      }
    }

    &:first-child {
      margin: 0 3rem 0 0;

      @include md {
        margin: 0 20px 0 0;
      }

      @include xs {
        margin: 0 10px 0 0;
      }
    }

    svg {
      width: 3.3rem;
      height: 4.5rem;
      fill: $colorWhite;
      transition: fill .3s;

      @include md {
        width: 15px;
        height: 25px;
      }
    }

    &--prev {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  &__slider {
    margin: 0 -3rem 0 0;

    @include md {
      margin: 0 -30px;
    }

    .swiper {
      padding: 2.5rem 0 .5rem;
    }

    .swiper-slide {
      &:nth-child(3) {
        .benefits__item-bg {
          height: 31.6rem;

          @include xs {
            height: 160px;
          }
        }
      }
    }
  }

  &__item {
    padding: 0 3rem 0 0;

    @include md {
      margin: 0 10px;
      padding: 0;
    }

    &:hover {

      .benefits__item-inner {
        transform: translateY(-2rem);
        background: $colorBlack;
        border-color: $colorGold;
        color: $colorGold;
      }

      .benefits__item-bg {
        stroke: $colorGold;
      }

      .benefits__item-icon svg {
        fill: $colorWhite;
      }

      .benefits__item-name {
        border-color: $colorGold;
      }
    }
  }

  &__item-inner {
    position: relative;
    min-height: 50rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4rem;
    background-color: $colorWhite;
    color: $colorBlack;
    padding: 6rem 4rem;
    border: 1px solid transparent;
    overflow: hidden;
    transition: border .3s, background .3s, color .3s, transform .3s;

    @include md {
      min-height: 400px;
      padding: 40px 25px;
    }

    @include xs {
      min-height: 315px;
    }
  }

  &__item-bg {
    position: absolute;
    top: -1px;
    right: -1px;
    max-width: 90%;
    height: 26rem;
    stroke-width: 2;
    stroke: $colorBlack;

    @include xs {
      max-width: 80%;
      height: 120px;
    }
  }

  &__item-icon {
    max-width: 3.5rem;
    margin: 0 0 2rem;

    svg {
      fill: $colorBlack;
      transition: fill .3s;
    }
  }

  &__item-content {
    margin: auto 0 0;
    width: 100%;
  }

  &__item-num {
    display: inline-block;
    font-family: $font-family-belgro;
    font-size: 3.6rem;
    font-weight: 900;
    letter-spacing: 0.1rem;
    margin: 0 0 3rem;

    @include md {
      font-size: 28px;
      margin: 0 0 20px;
    }

    @include xs {
      font-size: 23px;
    }
  }

  &__item-name {
    font-size: 2.4rem;
    font-weight: 700;
    border-top: 1px solid #707070;
    padding: 3rem 0 0;
    line-height: 1.33;
    min-height: 10rem;

    @include md {
      font-size: 18px;
      min-height: 70px;
      padding: 20px 0 0;
    }

    @include xs {
      min-height: 60px;
      font-size: 14px;
    }
  }

  &__footer {
    text-align: center;
    margin: 7rem 0 0;

    @include md {
      margin: 40px 0 0;
    }
  }
}
