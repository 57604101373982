.wrapper{ 
	transition: all 1s ease;
	opacity: 0;
	z-index: 1;
	background-image: none;
	&--show{
		transition: all 1s ease;
		opacity: 1;
	}
}

.main__ransparent{
}

.main__bg{
	// background-image: radial-gradient(100rem 100rem at 49.06% .46%,#292b2f 0%,#131415 44%,#0d0e0f 74%,#000 100%);
	// padding-top: 10rem;
}

.hero{
	// padding-bottom: 0;
}

body.no-scroll{
	height: 100%;
}

body.h-auto{
	height: auto;
}

body{
	background: #000;
	// background-image: radial-gradient(100rem 100rem at 49.06% .46%,#292b2f 0%,#131415 44%,#0d0e0f 74%,#000 100%);
	// height: auto;
}

.scroll-area {
	&.no-scroll{
		pointer-events: none;
	}
}


.header{
	transition: all .5s .2s ease;
	opacity: 0;
	transform: translateY(-100%);

	&.show{
		opacity: 1;
		transform: translateY(0);;
	}
}

.hero__wrap{
	transition: all .8s .6s ease;
	opacity: 0;
	transform: translateY(5%);

	&.show{
		opacity: 1;
		transform: translateY(0);;
	}
}

.js-stats{
	position: fixed;
	top: 0;
	left: 0;
	background-color: white;
	z-index: 100;
	color: #000;
	font-size: 20px;
	left: 50%;
}

.canvas-wrap{ 
	.scroll-down{
		display: block;
		position: fixed;
		bottom: 20px;
		left: 0;
		right: 0;
		z-index: 5;
		transition: all .6s ease;
	}
}

.showHtml .canvas-wrap .scroll-down {
	opacity: 0;
}