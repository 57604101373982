*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-size: 10px;

  @media (max-width: 1919px) and (min-width: 992px) {
    font-size: calc((.54vw));
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 1.65;
  font-family: $font-family-montserrat;
  color: $colorWhite;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include md {
    font-size: 18px;
  }

  @include sm {
    font-size: 16px;
  }

  @include xs {
    font-size: 15px;
  }
}

a {
  text-decoration: none;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

ul {
  list-style-type: none;
}

ol {
  list-style: none;
}

textarea {
  resize: none;
}

input,
textarea {
  border-radius: 0;

  &::placeholder {
    opacity: 1;
  }

  &:invalid {
    box-shadow: none;
  }
}

select {
  border-radius: 0;
}

// ie11 X removal
input {

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
}

// chrome search X removal
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
}

// input[number] arrows removal
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;

  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

// ios button \ inputs reset
select,
textarea,
input:matches([type="email"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="url"]) {
  appearance: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  appearance: none;
}
