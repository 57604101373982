.menu {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  width: 100%;
  height: 100%;
  background: $colorBlack;
  z-index: 2;
  transition: 0.3s;
  min-height: 100vh;

  .container {
    position: relative;
    display: flex;
    height: 100%;
    overflow: auto;
    padding-top: 12rem;
    padding-bottom: 5rem;
    flex-direction: column;
    justify-content: center;

    @include md {
      justify-content: flex-start;
      padding-bottom: 7rem;
    }

    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      background: $colorBlack;
      height: 12rem;
      width: 100%;
    }
  }

  &.active {
    transform: translateX(0);
  }

  &__actions {
    display: none;

    @include md {
      display: block;
    }
  }

  &__item {
    li {
      font-family: $font-family-belgro;
      text-align: center;
      font-weight: 900;
      font-size: 3.6rem;
      border-bottom: 1px solid #707070;
      padding: 2rem 0;

      @include md {
        font-size: 22px;
        padding: 15px 0;
      }

      @include xs {
        font-size: 21px;
      }
    }

    a {
      color: $colorWhite;
      transition: color .3s;

      &:hover {
        color: $colorGold;
      }
    }
  }

  &__title {
    margin: 0 0 7rem;

    @include md {
      display: none;
    }
  }

  &__nav {
    margin: 0 0 2rem;
    border-top: 1px solid #707070;
    counter-reset: menu-count;

    li {
      text-align: left;

      @include md {
        text-align: center;
      }

      &::before {
        content: counter(menu-count)'/';
        counter-increment: menu-count;
        margin: 0 4.5rem 0 0;
        color: $colorGold;

        @include md {
          content: none;
        }
      }
    }
  }

  .soc-sm {
    align-self: center;
    margin: auto 0 0;
    display: none;
    font-size: 17px;

    @include md {
      display: flex;
    }
  }
}
