.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  &__wrap {
    display: flex;
    align-items: center;
    padding: 4rem 0;

    @include md {
      padding: 30px 0;
      justify-content: space-between;
    }

    @include xs {
      padding: 20px 0;
    }
  }

  &__burger {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    width: 6.4rem;
    height: 3.2rem;
    background: transparent;
    z-index: 6;
    cursor: pointer;
    position: relative;
    margin: 0 5.4rem 0 0;
    border: none;

    @include md {
      width: 37px;
      height: 20px;
    }

    &:hover {

      span,
      &::before,
      &::after {
        background: $colorGold;
      }
    }

    @include md {
      margin: 0 15px 0 0;
    }

    span {
      background: $colorWhite;
      width: 5rem;
      height: .4rem;
      margin: 1rem 0;
      flex-shrink: 0;
      border-radius: .4rem;
      pointer-events: none;
      transition: 0.3s;

      @include md {
        width: 31px;
        height: 3px;
        margin: 8px 0;
      }
    }

    &::before,
    &::after {
      content: '';
      background: $colorWhite;
      width: 6rem;
      height: .4rem;
      flex-shrink: 0;
      border-radius: .4rem;
      transition: 0.3s;

      @include md {
        width: 37px;
        height: 3px;
      }
    }

    &::after {
      width: 4rem;

      @include md {
        width: 25px;
      }
    }

    &.active {
      span {
        transform: scale(0);
      }

      &::before {
        width: 4rem;
        left: calc(50% - 2rem);
        position: absolute;
        top: 1.4rem;
        transform: rotate(45deg);

        @include md {
          width: 25px;
          top: 8px;
          left: calc(50% - 14px);
        }
      }

      &::after {
        position: absolute;
        left: calc(50% - 2rem);
        bottom: 1.4rem;
        transform: rotate(-45deg);

        @include md {
          left: calc(50% - 14px);
          bottom: 9px;
        }
      }
    }
  }

  &__soc {
    width: 43rem;
    display: flex;
    font-size: 1.8rem;
    align-items: center;
    margin: 0 3rem 0 0;

    @include md {
      display: none;
    }

    &.open {

      .header__soc-inner {
        width: 39rem;
      }

      .header__soc-open {
        display: none;
      }

      .soc-sm__wrap {
        display: flex;
      }

      .header__soc-close {
        display: block;
      }
    }
  }

  &__soc-inner {
    overflow: hidden;
    width: 22.7rem;
    height: 6.2rem;
    transition: width .5s;
    align-items: flex-start;
    transition: width .3s;

    &>p {
      flex-shrink: 0;
    }

    .soc-sm__wrap {
      display: none;
    }
  }

  &__soc-open {
    width: 3rem;
    height: 3.4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    margin: 0 1.2rem 0 0;
    cursor: pointer;

    &:hover {
      svg {
        fill: $colorGold;
      }
    }

    svg {
      width: 100%;
      height: 100%;
      fill: $colorWhite;
      transition: fill .3s;
    }
  }

  &__soc-close {
    display: none;
    position: relative;
    width: 6rem;
    height: 6rem;
    flex-shrink: 0;
    background: transparent;
    border-radius: 3rem;
    border: 1px solid $colorGold;
    cursor: pointer;
    margin: 0 0 0 1.6rem;

    &:hover {

      &::before,
      &::after {
        background: $colorGold;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 2.7rem;
      left: calc(50% - 1.3rem);
      width: 2.7rem;
      height: .4rem;
      border-radius: .4rem;
      background: $colorWhite;
      transform: rotate(45deg);
      transition: background .3s;
    }

    &::after {
      content: '';
      position: absolute;
      left: calc(50% - 1.3rem);
      bottom: 2.7rem;
      width: 2.7rem;
      height: .4rem;
      border-radius: .4rem;
      background: $colorWhite;
      transform: rotate(-45deg);
      transition: background .3s;
    }
  }

  &__logo {
    max-width: 33.7rem;
    width: 100%;
    flex-shrink: 0;
    margin: 0 2rem;

    @include md {
      max-width: 220px;
    }

    @include xs {
      max-width: 164px;
    }
  }

  &__actions {
    margin: 0 0 0 auto;

    @include md {
      display: none;
    }
  }

  &__link {
    font-size: 2.4rem;
    font-weight: 600;
    color: $colorWhite;
    text-decoration: underline;
    margin: 0 4rem 0 0;
    transition: color .3s;

    &:hover {
      color: $colorGold;
    }

    &--border {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-width: 24.8rem;
      min-height: 6rem;
      border-radius: 3rem;
      border: 1px solid $colorGold;
      padding: .2rem 2rem;
    }
  }

  &__lang {
    min-width: 8.6rem;
    position: relative;
    font-size: 2.4rem;
    font-weight: 600;
    cursor: pointer;

    @include md {
      font-size: 18px;
      min-width: 65px;
      margin: 0 0 0 15px;
    }

    @include xs {
      min-width: 56px;
      font-size: 15px;
    }

    &.open {

      .header__lang-head svg {
        transform: rotate(-180deg);
      }

      ul {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    }

    ul {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;
      width: 100%;
      padding: 1rem .5rem .5rem;
      border-top: none;
      transition: opacity 0.3s, visibility 0.3s;

      li {
        &:not(:last-of-type) {
          margin: 0 0 1rem;
        }

        button {
          font-size: inherit;
          font-weight: inherit;
          cursor: pointer;
          background: transparent;
          border: none;
          color: #fff;
          transition: color .3s;

          &:hover {
            color: $colorGold;
          }
        }
      }
    }
  }

  &__lang-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: color .3s;

    &:hover {
      color: $colorGold;

      svg {
        stroke: $colorGold;
      }
    }

    svg {
      width: 2.2rem;
      height: 1rem;
      flex-shrink: 0;
      stroke: $colorWhite;
      margin: 0 0 0 .9rem;
      transition: stroke .3s, transform 0.3s;

      @include md {
        width: 15px;
        height: 8px;
        margin: 0 0 0 7px;
      }
    }
  }

  &__menu {}
}
