@import "styles/fonts";
@import "styles/normalize";
@import "styles/variables";

$black: #151515;
$yellow: #ffc800;

$ffBold: "Navigo";
$ffRegular: "ALS_Hauss";

* {
	box-sizing: border-box;
	user-select: none;
}

.title {
	font-family: "grotesk";
	line-height: 1;
	text-transform: uppercase;
}
html {
	// font-size: calc(100vw / 1920);
	font-size: calc(100vw / 1920);

	color: white;
	scroll-behavior: smooth;

	@media (min-width: 1920px) {
		font-size: 1px;
	}
}
html,
body {
	height: 100%;
}

body {
	min-height: 768px;
	margin: 0;
	// overflow-x: hidden;
	// overflow: hidden;
	// font-family: "regular";
	// min-height: 100vh;
	// color: white;
	// position: absolute;
	// top: 0;
	// left: 0;
	// width: 100%;
	font-family: $ffRegular;
	// font-size: 16 p x;
	font-size: 16px;

	@media (min-width: 1920px) {
		font-size: 18px;
	}
}

img {
	max-width: 100%;
}

p,
ul {
	margin: 0;
	padding: 0;
}

a {
	color: white;
	text-decoration: none;
}

ul {
	list-style: none;
}
li {
	padding: 0;
}

//debug
* {
	//   outline: 5px solid #f00 !important;
	//   opacity: 1 !important;
	//   visibility: visible !important;
	//   background-color: rgba($color: #000000, $alpha: .3);
}
.debug * {
	outline: 5px solid rgb(111, 0, 255) !important;
	opacity: 1 !important;
	visibility: visible !important;
	background-color: rgba($color: #000000, $alpha: 0.3);
}

.canvas-wrap {
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
	height: 100%;
	width: 100%;
	overflow: hidden;
	// display: none;
	background-color: black;
	canvas {
		position: absolute;
		left: 50%;
		right: 0;
		top: 0;
		bottom: 0;
		transform: translate(-50%, 0);
		margin: auto;

		// background-color: transparent;
		// background-color: black;
		// background-color: pink;
	}
}
.no-scroll {
	overflow: hidden;

	&::-webkit-scrollbar {
		opacity: 0;
		// display: none;
	}

	&::-webkit-scrollbar-thumb {
		box-shadow: none;
		background: red;
	}
}

.container {
	padding: 0 10px;
	max-width: 1840px;
	margin: auto;
}

.smooth-scroll {
	overflow-y: hidden !important;
}
// base end
body {
	// overflow-x: hidden;
	// overflow-y: hidden;
	position: relative;
}

.canvas-wrap__bg{
	// background-color: red;
	position: absolute;
	width: 100%;
	height: 100%;

	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.canvas-wrap{
	z-index: 1;
	// height: 2000px;
	overflow: hidden;
	&.js-fixed{
		position: fixed;
	}
	&.js-fixed canvas{
		position: fixed;
	}
}
.canvas-wrap canvas{
	top: 0;
	bottom: auto;
	z-index: 1;
}

.scroll-area {
	// background: pink;
	position: fixed;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2;
	height: 100%;
	// overflow: scroll;
	overflow: auto;
}
.scroll-area__scroll {
	height: 5000px;
}
.scroller-area{
	position: relative;
}
.scroll-area__letters-broke{
	position: absolute;
	top: 0px;
	width: 100%;
	height: 1px;
	height: 1500px;
}
.scroll-area__letters-up{
	position: absolute;
	top: 1000px;
	width: 100%;
	height: 1px;
}
.no-scroll{
	overflow: hidden;
}
body{
}





